@import '../../../assets/global-style/mixin';

.brands {
    padding: 48px 0;
    background-color: var(--color-blue-second);

    &__flex {
        display: flex;
    }

    &__el {
        &--links {
            width: 20%;
            margin-right: 48px;

            @media screen and (max-width: 767px) {
                display: none;
            }
        }

        &--hexagon {
            flex-grow: 1;
        }
    }

    &__hexagon-list {
        display: grid;
        grid-template-columns: repeat(4, 200px);
        gap: 12px;

        @include before_1200 {
            grid-template-columns: repeat(3, 200px);
        }

        @include before_1024 {
            grid-template-columns: repeat(2, 200px);
        }

        @include before_768 {
            grid-template-columns: repeat(2, 150px);
        }
    }

    &__hexagon-item {
        width: 200px;
        height: 200px;
        // border: 3px solid #c1b3b3;

        @media screen and (max-width: 767px) {
            width: 150px;
            height: 150px;
        }
    }

    &__link-item {
        &:not(:last-child) {
            margin-bottom: 12px;
        }
    }

    &__link-text.link {
        font-size: 24px;
        line-height: 140%;
        color: var(--color-blue);

        &:hover {
            .link__border {
                background-color: var(--color-blue);
            }
        }
    }

    &__hexagon-link {
        position: relative;
        display: inline-block;
        width: 200px;
        height: 200px;
        // -webkit-clip-path: polygon(50% 0%, 100% 25%, 100% 75%, 50% 100%, 0% 75%, 0% 25%);
        // clip-path: polygon(50% 0%, 100% 25%, 100% 75%, 50% 100%, 0% 75%, 0% 25%);
        background-color: var(--color-blue);
        border-radius: 25px 5px 25px 5px;
        // transition: transform 0.5s;

        @media screen and (max-width: 767px) {
            width: 150px;
            height: 150px;
        }

        &:hover {
            .brands__hexagon-img {
                transform: translate(-50%, -50%) scale(1.05);
            }
        }
    }

    &__hexagon-img {
        position: absolute;
        top: 50%;
        left: 50%;
        width: 180px;
        height: 180px;
        transform: translate(-50%, -50%);
        background-color: #ffffff;
        // -webkit-clip-path: polygon(50% 0%, 100% 25%, 100% 75%, 50% 100%, 0% 75%, 0% 25%);
        // clip-path: polygon(50% 0%, 100% 25%, 100% 75%, 50% 100%, 0% 75%, 0% 25%);
        border-radius: 20px 5px 20px 5px;
        object-fit: contain;
        object-position: center;
        transition: transform 0.5s;

        @media screen and (max-width: 767px) {
            width: 130px;
            height: 130px;
        }
    }
}
