@import '../../../assets/global-style/mixin';

.button {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px 30px;

    font-weight: 400;
    font-size: 18px;
    line-height: 25px;
    color: #ffffff;
    text-decoration: none;
    text-align: center;

    background-color: var(--button-primery-color);
    border: 1px solid var(--button-primery-color);
    border-radius: 15px;

    box-sizing: border-box;
    cursor: pointer;
    outline: none;
    transition: 0.5s;
    webkit-appearance: none; //убирает дефолтные стили в ios

    &:hover {
        color: var(--button-primery-color);
        background-color: var(--color-white);
    }

    &:active {
        opacity: 0.5;
        transition: 0.1s;
    }

    &:focus {
        opacity: 0.9;
        transition: 0.1s;
    }

    &[disabled] {
        opacity: 0.8;
        pointer-events: none;
    }

    &--blue {
        background-color: var(--color-blue);
        border: 1px solid var(--color-blue);

        &:hover {
            color: var(--color-blue);
            background-color: var(--color-white);
        }
    }
}
