@import '../../../assets/global-style/mixin';

.title {
    margin-bottom: 48px;
    @include adaptive-font(30, 20, 1240);
    line-height: 140%;
    color: var(--color-black);

    &--fs24 {
        margin-bottom: 12px;
        @include adaptive-font(24, 18, 1240);
    }

    &--center {
        text-align: center;
    }

    &--white {
        color: var(--color-white);
    }
}
