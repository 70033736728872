.grid {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    // transform-style: inherit;

    &__main {
        display: flex;
        flex-direction: column;
        flex-grow: 1;
    }

    &__content {
        display: flex;
        flex-grow: 1;
    }

    // &::before {
    //     content: '';
    //     display: block;
    //     position: absolute;
    //     left: 0;
    //     right: 0;
    //     bottom: 0;
    //     top: 0;
    //     z-index: -1;
    //     transform-origin: center;
    //     width: 100vw;
    //     height: 100vh;
    //     background-image: url('~images/pages/car.png');
    //     background-repeat: no-repeat;
    //     background-size: cover;
    //     transition: 1s;
    //     /* Параллакс */
    //     transform: translateZ(-1px) scale(2);
    // }

    // &--inner {
    //     &::before {
    //         display: none;
    //     }
    // }
}
