.header {
    padding: 24px 0 32px;
    background-color: var(--color-blue-second);

    &__top {
        margin-bottom: 12px;
    }

    &__flex {
        display: flex;
        justify-content: space-between;
        align-items: center;

        @media screen and (max-width: 767px) {
            flex-direction: column;
            align-items: flex-start;
        }
    }

    &__el {
        &--search {
            display: flex;
            align-items: center;
            justify-content: flex-end;
            flex-grow: 1;
            max-width: 300px;
        }

        &--phone {
            @media screen and (max-width: 767px) {
                margin-bottom: 12px;
            }
        }
    }

    &__phone {
        display: flex;
        align-items: center;
        font-family: var(--font-primery);
        font-size: 18px;
        color: var(--color-black);
        text-decoration: none;
        transition: opacity 0.5s;

        &:hover {
            color: var(--color-black);
            opacity: 0.6;
        }

        &:first-child {
            margin-bottom: 12px;
        }
    }

    &__icon-phone {
        margin-right: 12px;
    }

    &--brown {
        margin-bottom: 48px;

        // .header__phone {
        //     color: var(--color-white);

        //     &:hover {
        //         color: var(--color-white);
        //     }
        // }
    }

    // &--main {
    //     margin-bottom: 0;
    // }
}
