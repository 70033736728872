.wrapper {
    width: 100%;
    max-width: 1240px;
    padding: 0 50px;
    margin: 0 auto;
    box-sizing: border-box;

    @media screen and (max-width: 767px) {
        padding: 0 20px;
    }

    &--h100 {
        display: flex;
        flex-direction: column;
        flex-grow: 1;
    }
}
