.navigation {
    position: relative;
    padding-top: 36px;

    &__flex {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    &__block {
        display: flex;
        align-items: center;
    }

    &__link {
        font-size: 14px;
        line-height: 20px;
        letter-spacing: 0.02em;
        color: var(--color-gray);
        text-decoration: none;
        transition: opacity 0.5s;

        &:not(:last-child) {
            margin-right: 24px;
        }

        &:hover {
            opacity: 0.6;
        }
    }
}
