.breadcrumbs {
    display: flex;
    align-items: center;
    margin-bottom: 48px;

    .item {
        position: relative;
        padding-right: 15px;
        margin-right: 12px;
        font-size: 14px;
        line-height: 16px;
        color: var(--color-black);

        &:after {
            content: '';
            position: absolute;
            right: 0;
            top: 50%;
            transform: translate(0, -50%) rotate(45deg);
            width: 10px;
            height: 10px;
            border-top: 1px solid var(--color-gray);
            border-right: 1px solid var(--color-gray);
        }

        &:last-child:after {
            display: none;
        }

        a {
            font-family: var(--font-primery);
            font-size: 14px;
            line-height: 16px;
            color: var(--color-blue);
            text-decoration: none;
        }
    }
}
