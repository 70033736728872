.link-icon {
    display: flex;
    align-items: center;
    font-family: var(--font-primery);
    text-decoration: none;

    &__el {
        &:first-child {
            margin-right: 8px;
        }

        &--text {
            font-size: 14px;
            line-height: 18px;
            letter-spacing: 0.02em;
            color: var(--color-gray);

            &-blue {
                color: var(--color-blue);
            }
        }

        &--svg {
            flex-shrink: 0;
            fill: var(--icon-primery-color);
        }
    }
}
