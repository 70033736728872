.link {
    position: relative;
    display: inline-block;
    font-family: var(--font-primery);
    font-size: 18px;
    line-height: 140%;
    color: var(--color-blue);
    text-decoration: none;

    &__border {
        position: absolute;
        bottom: -2px;
        left: 0;
        width: 0;
        height: 2px;
        background-color: transparent;
        transition: width 0.5s;
    }

    &:hover {
        .link__border {
            width: 100%;
            background-color: var(--color-blue);
        }
    }
}
