@import '../../components/link-icon/link-icon';
@import '../../components/structure/Grid/grid';
@import '../../components/structure/Header/header';
@import '../../components/structure/Navigation/navigation';
@import '../../components/structure/Main/main';
@import '../../components/structure/Wrapper/wrapper';
@import '../../components/structure/Menu/menu';
@import '../../components/structure/Brands/brands';
@import '../../components/structure/Copmany/company';
@import '../../components/structure/Contacts/contacts';
@import '../../components/structure/Footer/footer';
@import '../../components/buttons/Button/Button.scss';
@import '../../components/structure/Breadcrumbs/breadcrumbs';
@import '../../components/images/Icon/icon';
@import '../../components/links/link/link';
@import '../../components/typography/Title/Title';
@import '../../components/other/SearchBlock/searchBlock';
@import '../../components/other/List/list';
@import '../../components/other/ImgBox/imgBox';

.search {
    padding: 24px 0 80px;

    &__form {
        margin-bottom: 48px;
    }

    &__input {
        width: 100%;
        margin-bottom: 24px;
        padding: 12px;
        border: 1px solid var(--color-brown-first);
    }

    &__result {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: 24px;

        @media screen and (max-width: 1023px) {
            grid-template-columns: repeat(2, 1fr);
        }

        @media screen and (max-width: 550px) {
            grid-template-columns: 1fr;
        }
    }

    &__product {
        display: flex;
        flex-direction: column;
        border-radius: 10px;
        box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
        border: 1px solid var(--color-brown-first);
        overflow: hidden;
        transition: 0.5s;

        &:hover {
            box-shadow: 0 0 20px rgba(0, 0, 0, 0);
        }
    }

    &__product-link {
        display: flex;
        flex-direction: column;
        flex-grow: 1;
        font-size: var(--font-primary);
        color: var(--text-primary-color);
        text-decoration: none;
    }

    &__product-info {
        flex-grow: 1;
        margin-bottom: 8px;
    }

    &__product-content {
        display: flex;
        flex-direction: column;
        flex-grow: 1;
        padding: 12px 24px 24px 24px;
        border: 1px solid transparent;
        border-top: none;
        border-radius: 0 0 10px 10px;
        transition: 0.5s;
    }

    &__product-el {
        &--left {
            flex-shrink: 0;
            width: 20%;
            margin-right: 24px;
        }

        &--center {
            flex-grow: 1;
        }

        &--right {
            align-self: center;
            flex-shrink: 0;
            margin-left: 12px;
        }
    }

    &__product-title {
        display: block;
        font-weight: bold;
        font-size: 18px;
        line-height: 140%;
        color: var(--color-blue);
        text-decoration: none;
        cursor: pointer;

        @media screen and (max-width: 767px) {
            font-size: 18px;
        }
    }

    &__product-features {
        display: flex;
        justify-content: space-between;
        align-items: center;

        @media screen and (max-width: 767px) {
            font-size: 14px;
        }
    }

    &__feature-value {
        width: 48%;
    }

    &__feature-description {
        width: 48%;
    }

    &__product-article {
        display: flex;
        align-items: center;
        margin-bottom: 12px;
        font-size: 18px;
        line-height: 140%;

        @media screen and (max-width: 767px) {
            font-size: 16px;
        }
    }

    &__article-value {
        margin-right: 5px;
        font-weight: bold;
    }

    &__img-box {
        cursor: pointer;
    }

    &__product-description {
        @media screen and (max-width: 767px) {
            font-size: 14px;
        }
    }

    &__dropdown {
        display: none;
    }

    &__dropdown-button {
        display: flex;
        justify-content: center;
        margin-top: 12px;
    }

    &__plus-button {
        position: relative;
        width: 40px;
        height: 40px;
        border: 1px solid var(--color-blue);
        border-radius: 50%;
        background-color: var(--color-white);
        cursor: pointer;

        &::before,
        &::after {
            content: '';
            position: absolute;
            left: 50%;
            top: 50%;
            width: 25px;
            height: 2px;
            background-color: var(--color-blue);
            transform: translate(-50%, -50%);
        }

        &::after {
            transform: translate(-50%, -50%) rotate(90deg);
            transition: 0.5s;
        }

        &.active {
            &::after {
                transform: translate(-50%, -50%) rotate(0);
            }
        }
    }
}
