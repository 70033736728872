.search-block {
    width: 100%;
    max-width: 300px;

    &__form {
        display: flex;
        align-items: center;
    }

    &__input {
        width: 100%;
        height: 42px;
        padding-left: 10px;
        border: 2px solid #2e75b6;
        border-radius: 5px 0 0 5px;
        outline: none;
        background: #ffffff;
        color: var(--color-black);
    }

    &__button {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 42px;
        height: 42px;
        border: none;
        background: #2e75b6;
        border-radius: 0 5px 5px 0;
        cursor: pointer;
    }

    &__icon-search {
        fill: #ffffff;
    }
}
