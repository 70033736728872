.menu {
    display: flex;
    align-items: center;
    justify-content: space-between;

    &__logo {
        width: 100%;
        max-width: 130px;
        height: auto;
    }

    &__list {
        display: flex;
        align-items: center;
    }

    &__el {
        &--desktop {
            @media screen and (max-width: 767px) {
                display: none;
            }
        }

        &--mobile {
            @media screen and (min-width: 768px) {
                display: none;
            }
            @media screen and (max-width: 767px) {
                display: block;
            }
        }
    }

    &__item {
        &:not(:last-child) {
            margin-right: 32px;
        }
    }

    &__link {
        font-family: var(--font-primery);
        font-size: 18px;
        color: var(--color-black);
        text-decoration: none;
        transition: opacity 0.5s;

        &:hover {
            color: var(--color-black);
            opacity: 0.6;
        }
    }

    &--mobile {
        position: fixed;
        top: 0;
        left: 0;
        bottom: 0;
        width: 200px;
        height: 100vh;
        z-index: 100;
        transform: translateX(-100%);
        transition: transform 0.5s ease-in-out;

        &.active {
            transform: translateX(0);
        }

        .menu__el {
            width: 100%;
            height: 100%;
            padding: 40px 20px;
            background-color: var(--color-blue);
        }

        .menu__list {
            flex-direction: column;
            align-items: flex-start;
            height: 100%;
        }

        .menu__item {
            &:not(:last-child) {
                margin-right: 0;
                margin-bottom: 12px;
            }
        }

        .menu__link {
            color: var(--color-white);
        }
    }
}
