.footer {
    background-color: var(--color-black);

    &__text {
        padding: 32px 0;
        font-size: 20px;
        line-height: 30px;
        text-align: center;
        color: var(--color-white);
    }
}
