//Медиа запросы для разных ширин экрана
//Пример использования: @include desktop_1200 {font-size: 20px;}
@mixin before_1366 {
    @media screen and (max-width: 1365px) {
        @content;
    }
}

@mixin before_1200 {
    @media screen and (max-width: 1199px) {
        @content;
    }
}

@mixin before_1024 {
    @media screen and (max-width: 1023px) {
        @content;
    }
}

@mixin before_768 {
    @media screen and (max-width: 767px) {
        @content;
    }
}

@mixin before_480 {
    @media screen and (max-width: 479px) {
        @content;
    }
}

//Добавление адаптивного шрифта
//Пример использования: @include adaptive-font(40, 24, 1280);
@mixin adaptive-font($pcSize, $mobSize, $maxWidth) {
    $addSize: $pcSize - $mobSize;
    $addMobSize: $addSize + $addSize * 0.7;

    @media (max-width: 767px) {
        font-size: calc(#{$mobSize + px} + #{$addMobSize} * ((100vw - 320px) / #{$maxWidth}));
    }

    @media (min-width: 767px) {
        font-size: calc(#{$mobSize + px} + #{$addSize} * (100vw / #{$maxWidth}));
    }
}
