.contacts {
    padding: 48px 0;

    &__text {
        margin-bottom: 12px;
        font-size: 18px;
        line-height: 140%;
        color: var(--color-black);
        text-align: center;

        @media screen and (max-width: 480px) {
            font-size: 16px;
        }

        &--mb64 {
            margin-bottom: 64px;
        }
    }

    &__flex {
        display: flex;
        justify-content: space-between;

        @media screen and (max-width: 767px) {
            flex-direction: column;
        }
    }

    &__el {
        width: 48%;

        @media screen and (max-width: 767px) {
            width: 100%;
        }

        &:not(:last-child) {
            @media screen and (max-width: 767px) {
                margin-bottom: 24px;
            }
        }
    }

    &__block {
        display: flex;
        align-items: center;
        padding: 24px 32px;
        background-color: #fafafa;

        &:not(:last-child) {
            margin-bottom: 24px;
        }
    }

    &__block-el {
        &:first-child {
            flex-shrink: 0;
            margin-right: 24px;
        }
    }

    &__block-name {
        font-weight: bold;
        margin-bottom: 12px;
        font-size: 34px;
        line-height: 140%;
        color: var(--color-black);
    }
}
