@import '../../../assets/global-style/mixin';

.list {
    margin-bottom: 24px;

    &__item {
        position: relative;
        padding-left: 20px;
        font-size: 18px;
        line-height: 26px;

        @include before_768 {
            font-size: 14px;
            line-height: 22px;
        }

        &:not(:last-child) {
            margin-bottom: 16px;
        }

        &:before {
            content: '';
            position: absolute;
            top: 8px;
            left: 0;
            width: 8px;
            height: 8px;
            background-color: #c1b3b3;
            border-radius: 50%;
        }
    }
}
