.company {
    padding: 72px 0;
    background-color: #ccbfbf;

    &__container {
        width: 80%;
        margin: 0 auto;
        padding: 32px;
        background-color: #ffffff;
        border-radius: 12px;
        border-left: 3px solid #6592e6;

        @media screen and (max-width: 767px) {
            width: 100%;
        }
    }

    &__text {
        margin-bottom: 12px;
        font-size: 18px;
        line-height: 140%;
        color: var(--color-black);

        @media screen and (max-width: 480px) {
            font-size: 16px;
        }
    }

    &--gray {
        background-color: #c1b3b3;

        .company__container {
            background-color: #fafafa;
        }
    }
}
