.img-box {
    position: relative;
    padding-bottom: 56.25%;
    text-decoration: none;

    &__img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center;
    }

    &--brand-logo {
        .img-box__img {
            object-fit: contain;
        }
    }
}
